/** @format */ /** @format */
footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f7f7f7;
}
footer .content {
  color: #000 !important;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer .content h5 {
  margin-bottom: 8px;
}
footer .content ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 8px 0;
}
footer .content ul li {
  padding: 8px;
}
footer .content ul li p {
  font-weight: 600;
}
footer .content ul:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 599px) {
  footer .content ul:last-of-type {
    display: block;
  }
}
footer .content a {
  color: #000 !important;
}
footer .content svg {
  color: #000 !important;
}
footer .developer {
  width: 100%;
  background-color: #000;
  color: #fff;
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
footer .developer a {
  color: #fff !important;
}