/** @format */

#root {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

#root > main {
  flex: 1 !important;
}

:root {
  --font-color: #000;
  --background-color: #fff;
  --link-color: #000;
  --background-color-for-buttons: #000;
  --font-color-for-buttons: #fff;
  --divider-color: rgba(0, 0, 0, 0.1);
  --box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  --background-color-for-carousel: #7f7f7f;
  --stroke: hsl(0, 0%, 100%);
  --swiper-preloader-color: #000 !important;
}

/* 2 */
[data-theme="dark"] {
  --font-color: #fff;
  --background-color: #000;
  --link-color: #fff;
  --background-color-for-buttons: #fff;
  --font-color-for-buttons: #000;
  --divider-color: rgba(255, 255, 255, 0.1);
  --box-shadow: 0px 2px 4px -1px rgb(255 255 255 / 15%);
  --stroke: hsl(0, 0%, 100%);
  --swiper-preloader-color: #fff !important;
}

body {
  margin: 0;
  /* padding-top: 72px; */
  /* padding-bottom: 72px; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--font-color);
}

a {
  color: var(--link-color) !important;
}

.padding-top-for-body {
  padding-top: 72px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 599px) {
  .padding-top-for-body {
    padding-top: 64px;
  }
}

.suspense-component {
  width: 100%;
  height: 277px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.suspense-component.home-page {
  height: 100dvh;
}

.carousel-loader {
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
