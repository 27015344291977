/** @format */ /** @format */
.MuiDialogContent-root textarea {
  display: block;
  width: 50%;
  padding: 5px 9px;
  line-height: 18px;
  background-color: rgb(246, 246, 246);
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
}
.MuiDialogContent-root input[type=text] {
  display: block;
  width: 50%;
  padding: 5px 9px;
  margin-bottom: 8px;
  background-color: rgb(246, 246, 246);
  border: 1px solid transparent;
  border-radius: 6px;
  outline: none;
}

.MuiDialogActions-root button {
  background-color: var(--background-color-for-buttons) !important;
  color: var(--font-color-for-buttons) !important;
}

.reorder {
  display: flex;
  margin-bottom: 32px;
}
.reorder button {
  background-color: var(--background-color-for-buttons) !important;
  color: var(--font-color-for-buttons) !important;
}

.featured-dashboard .reorder {
  justify-content: flex-end;
}

.reorder-container {
  background: #f5f5f5;
  border: 1px solid #dedede;
  border-radius: 3px;
  padding: 8px 0;
}
.reorder-container .reorder-item {
  display: flex;
  background: #f5f5f5;
  border-bottom: 1px solid #e3e3e3;
}
.reorder-container .reorder-item .thumbnail {
  width: 60px;
  height: 52px;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e8e8e8;
}
.reorder-container .reorder-item .thumbnail .reorder-img {
  max-height: 52px;
  max-width: 60px;
  object-fit: cover;
}
.reorder-container .reorder-item .thumbnail p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 60px;
  font-size: 16px;
  font-weight: 600;
}
.reorder-container .reorder-item p {
  height: 85%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}
.reorder-container .reorder-item:last-child {
  border-bottom: 0;
}
.reorder-container button {
  background-color: var(--background-color-for-buttons) !important;
  color: var(--font-color-for-buttons) !important;
}

@keyframes loadingAnimation {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
}
.placeholder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: loadingAnimation 1s infinite;
}

.MuiLinearProgress-root {
  color: var(--font-color) !important;
}

svg {
  color: var(--font-color) !important;
}

.attached-caption {
  display: block;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  color: gray;
  max-width: 600px !important;
  margin: auto;
}

img.image {
  background-color: #cccccc !important;
  aspect-ratio: attr(width)/attr(height);
}