/** @format */
.go-top-button {
  display: block;
  position: fixed;
  width: 30px;
  height: 30px;
  right: 5%;
  bottom: 10%;
  z-index: 99;
  background-color: var(--background-color-for-buttons) !important;
  border-radius: 25px;
  line-height: 30px;
  transition: all 0.5s ease-in-out;
}
.go-top-button button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
.go-top-button button svg {
  color: var(--font-color-for-buttons) !important;
}
.go-top-button:hover {
  transform: scale(1.1);
}